import React from 'react'

import channelStat from '../../static/channel-stat-2022.pdf'

export default function Footer(props) {
  return (
    <div className='footer-container' id='contact'>
      <h1>Напишите нам <span>на&nbsp;</span><a href='mailto:adwylsa@gmail.com'>adwylsa@gmail.com</a></h1>

      <div className='footerLinks-container'>
        <div className='footerLinks-column'>
          <a target='blank' href='https://www.youtube.com/user/Wylsacom'>
            YouTube
          </a>

          <a target='blank' href='https://t.me/Wylsared'>
            Telegram
          </a>

          <a target='blank' href='https://vk.com/wylsacom'>
            VK
          </a>

          <a target='blank' href='https://www.tiktok.com/@fakewylsacom'>
            TikTok
          </a>
        </div>

        <div className='footerLinks-column'>
          <a target='blank' href='https://wylsa.com/'>
            Wylsa.com
          </a>

          <a target='blank' href='https://apps.apple.com/ru/app/wylsacom-media/id1481939376'>
            App Store
          </a>

          <a target='blank' href='https://play.google.com/store/apps/details?id=com.wylsacom.media&hl=ru'>
            Google Play
          </a>
        </div>

        <div className='footerLinks-column'>
          <a target='blank' href='https://docs.google.com/spreadsheets/d/1fOcAeps0CaBygkR9bmPinpkZ2LBYEzO_PJkYkfMKLrg'>
            Цены
          </a>
          <a target='blank' href={channelStat}>
            Статистика канала
          </a>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet'

import Share from '../../../static/Share.jpg'

export default function MetaData(props) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name='description' content={props.description} />
      <meta name='keywords' content='wylsacom media, wylsacom, wylsacom реклама' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Wylsacom Media' />
      <meta property='og:title' content={props.title} />
      <meta property='og:url' content='https://wylsacom.media/' />
      <meta property='og:type' content='website' />
      <meta property='og:description' content={props.description} />
      <meta property='og:locale' content='ru_RU' />
      <meta property='og:image' content={'https://wylsacom.media' + Share} />
      <meta property='og:image:secure_url' content={'https://wylsacom.media' + Share} />
      <meta property='og:image:width' content='1120' />
      <meta property='og:image:height' content='584' />
      <meta property='og:image:type' content='image/jpeg' />
      <script type="text/javascript" >{`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(73223227, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true
        });
      `}</script>
      <body className='page'/>
    </Helmet>
  )
}

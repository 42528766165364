import React from 'react'
import { Link } from 'gatsby'
import SmoothScroll from 'smooth-scroll'

export default class MenuBar extends React.Component {
  constructor() {
    super()

    this.handleMobileMenubar = this.handleMobileMenubar.bind(this)
    this.handleScroll = this.handleScroll.bind(this)

    const pageYOffset = typeof window !== `undefined` ? window.pageYOffset : 0

    this.state = {
      mobileMenubarOpen: false,
      prevScrollpos: pageYOffset,
      visible: true
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  renderItems() {
    let itemsElements = []
    const { items } = this.props

    items.map(function(item, i) {
      itemsElements.push(
        <a
          href      = {item.to}
          target    = {item.external ? '_blank' : null}
          rel       = {item.external ? 'noreferrer' : null}
          className = 'menubarItem'
          key       = {i}
        >
          {item.title}
        </a>
      )
    })

    return(
      itemsElements
    )
  }

  renderMobileItems() {
    let itemsElements = []
    let action = this.handleMobileMenubar
    const { items } = this.props

    items.map(function(item, i) {
      itemsElements.push(
        <a
          href      = {item.to}
          target    = {item.external ? '_blank' : null}
          rel       = {item.external ? 'noreferrer' : null}
          className = 'mobileMenbuarItem'
          onClick   = {action}
          key       = {i}
        >
          {item.title}
        </a>
      )
    })

    return(
      itemsElements
    )
  }

  handleScroll() {
    let { prevScrollpos } = this.state

    let currentScrollPos = typeof window !== `undefined` ? window.pageYOffset : 0
    let visible = prevScrollpos > currentScrollPos || currentScrollPos < 48 ? true : false

    this.setState({
      ...this.state,
      prevScrollpos: currentScrollPos,
      visible
    })
  }

  handleMobileMenubar() {
    this.setState({
      mobileMenubarOpen: !this.state.mobileMenubarOpen
    })

    this.props.action()
  }

  render() {
    let stickyClass = this.state.visible ? ' sticky' : ''
    let openClass   = this.state.mobileMenubarOpen ? ' open' : ''

    if (typeof window !== `undefined`) {
      let scroll = new SmoothScroll('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true,
        offset: '104px',
        easing: 'easeInOutQuad',
        updateURL: false
      })
    }

    return (
      <header className={openClass + stickyClass}>
        <div className='menubar-container'>
          <div className='menubarBreadcrumbs'>
            <Link
              to={this.props.internal ? '/' : '#'}
              className='menubarLogo'
            >
              wylsacom media
            </Link>

            {this.props.internal ? <span>&nbsp;/&nbsp;</span> : ''}

            <a href='#'>
              {this.props.internal}
            </a>
          </div>

          <div className='menubarItemsList'>
            {this.renderItems()}
          </div>

          <button
            className={this.state.mobileMenubarOpen ? 'mobileMenubarButton active' : 'mobileMenubarButton'}
            onClick={this.handleMobileMenubar}
          >
            <div></div>
            <div></div>
          </button>
        </div>

        <div
          className={this.state.mobileMenubarOpen ? 'mobileMenbuar-container active' : 'mobileMenbuar-container'}
        >
          <div className='mobileMenbuarItemsList'>
            {this.renderMobileItems()}
          </div>
        </div>
      </header>
    )
  }
}
